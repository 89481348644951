import React, { useState, useEffect } from "react"
import { Link } from "@StarberryUtils";
import Slider from "react-slick"
import {Container, Row,Col} from 'react-bootstrap'
import ContactCard from "../../ContactCard1/ContactCard"
import PortfolioImgMobile from "../../../images/static/portfolio_img_mobile.jpg"
import PortfolioImgTablet from "../../../images/static/portfolio_img_tablet.jpg"

import PortfolioImgMobile1 from "../../../images/static/portfolio_img_mobile1.jpg"
import PortfolioImgTablet1 from "../../../images/static/portfolio_img_tablet1.jpg"

import PortfolioImgMobile2 from "../../../images/static/portfolio_img_mobile2.jpg"
import PortfolioImgTablet2 from "../../../images/static/portfolio_img_tablet2.jpg"
import ScrollAnimation from 'react-animate-on-scroll'
import axios from "axios"
import "./SimilarProperty.scss"
// styles

// markup
const SimilarProperty = ( props ) => {
 
  //
  const [propItems, setPropItems] = useState([])

  const getitems = async url => {
    try {
      const { data } = await axios.get(url, {
        headers: {
          Authorization: `Bearer ${process.env.GATSBY_STRAPI_FORM_TOKEN}`,
        }})
      // 
      // console.log("PropertySimilar", data)
      setPropItems(data)
    } catch (error) {
      // console.error(error)
    }
  }

  useEffect(() => {
    // 
    let url =
      process.env.GATSBY_STRAPI_SRC +
      "/stb-lists/item/Property-details?pid=" +
      props.property_details_similar_property_id

    getitems(url)
  }, []);
  //

  const settings = {
    dots: false,
    speed: 800,
    slidesToShow: 3,
    slidesToScroll: 1,
    infinite: false,
    arrows: false,
    mobileFirst: true,
    responsive: [
      {
        breakpoint:1199,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
          infinite: true,
          dots: false,
        },
      },
      {
        breakpoint: 991,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
          infinite: true,
          dots: false,
        },
      },
      {
        breakpoint: 767,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          infinite: true,
          dots: true,
        },
      },
      {
        breakpoint: 99999,
        // settings: "unslick",
      },
    ],
  }

  return (
    propItems.length > 0 &&
    <React.Fragment>
      <div className="similar-property"> 
        <Container>
          <Row>
            <Col lg={12}>
              <h2>Similar properties that may interest you</h2>
              <div className="similar-property-list">
                <Slider {...settings}>
                  {
                    propItems &&
                    propItems.map(hit => {
                      // property details url structure
                      let uriStr = "";

                      if ( hit.department === "residential") {
                        if( hit.search_type === "sales" ) {
                          uriStr = `properties-for-sale/`
                        } else if ( hit.search_type === "lettings" ) {
                          uriStr = `properties-for-rent/`
                        }
                      } else if ( hit.department === "commercial") {
                        if( hit.search_type === "sales" ) {
                          uriStr = `commercial-properties-for-sale/`
                        } else if ( hit.search_type === "lettings" ) {
                          uriStr = `commercial-properties-for-rent/`
                        }
                      }
                      // property details url structure

                      // console.log("hit.images", hit.images);

                      return (
                        <Link to={'/'+uriStr+hit.slug+'-'+hit.id}>
                           <div className="animated">
                            <ContactCard
                              // cardImgDesktop={PortfolioImgMobile}
                              // cardImgTablet={PortfolioImgTablet}
                              // cardImgMobile={PortfolioImgMobile}
                              cardImg={hit.images}
                              cardTitle={hit.title+' in '+hit.display_address}
                              date={hit.price.toLocaleString()+" QR"}
                              cardId={hit.id}
                              cardTag={''}
                            />
                           </div>
                        </Link>
                      )
                    })
                  }
                </Slider>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  )
}

export default SimilarProperty
import * as React from "react"
import MapImg from "../../../images/map-img.jpg";
import MapImgTablet from "../../../images/map-table.jpg";
import mapMobile from "../../../images/map-mobile.jpg";

import GoogleSingleMap from "../../maps/google/single"
import "./Map.scss"
// styles

// markup
const Map = ( props ) => {

  return (
    props.property_details_map_data &&
    <React.Fragment>
      <div className="map-wrap">
         <div className="animated">
          { props.property_details_map_data.latitude && props.property_details_map_data.longitude &&
            <GoogleSingleMap lat={props.property_details_map_data.latitude} lng={props.property_details_map_data.longitude} height={"650px"}/>
          }
          {/* <picture>
            <source media="(min-width:992px)" srcSet={MapImg}/>
            <source media="(min-width:768px)" srcSet={MapImgTablet}/>
            <img src={mapMobile} alt=""/>
          </picture> */}
         </div>
      </div>
    </React.Fragment>
  )
}

export default Map
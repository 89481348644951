import * as React from "react"
import { Container,Row,Col } from "react-bootstrap"
import Breadcrumbs from "../Breadcrumbs/Breadcrumbs"
import ScrollAnimation from 'react-animate-on-scroll'
import {titleCase} from "../../common/utils"

import "./PropertyHeading.scss"
// styles

// markup
const PropertyHeading = ( props ) => {
  const SearchType = "lettings";
  const search_type = props?.property_details_heading_data?.search_type === SearchType;

  return (
    <React.Fragment>
      <div className="heading-block">
         <div className="animated">
          { props.property_details_heading_data &&
            <Breadcrumbs property_breadcrumb={props.property_details_heading_data} />
          }
            <div className="heading-block-wrap">
              { props.property_details_heading_data.display_address && 
                <h1>{props.h1_title}</h1>
              }
              <div className="price-row">
                { props.property_details_heading_data.price &&
                  <span className="current-price">{search_type && props.property_details_heading_data.facts.payment_terms ? props.property_details_heading_data.facts.payment_terms+" Rent" : ''} {props.property_details_heading_data.price.toLocaleString()} QR</span>
                }
                {/* <span className="old-price">1,300,500 QR</span> */}
              </div>
            </div>
         </div>
      </div>
    </React.Fragment>
  )
}

export default PropertyHeading
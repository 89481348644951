import { Link } from "@StarberryUtils";
import React, { useState, useRef } from "react"
import { Row, Col, Container, Breadcrumb, Card } from "react-bootstrap"
import Accordion from "react-bootstrap/Accordion"
import "./CollapseBlock.scss"
import AccordionContext from "react-bootstrap/AccordionContext"
import { useAccordionToggle } from "react-bootstrap/AccordionToggle"
import { useContext } from "react"

import HTMLReactParser from 'html-react-parser';
import $ from "jquery"
import { useEffect } from "react"

const CollapseBlock = ( props ) => {

  function ContextAwareToggle({ children, eventKey, callback }) {
    const currentEventKey = useContext(AccordionContext);
    const myRef = useRef(null);
    const decoratedOnClick = useAccordionToggle(
      eventKey,
      () => callback && callback(eventKey),
      
    );

    useEffect(()=>{

      if(currentEventKey){
        //console.log("currentEventKey", currentEventKey);
        setTimeout(function(){
          $('html, body').animate({
            scrollTop: $("#accord_"+currentEventKey).offset().top - 170
          }, 'smooth');
        }, 300)
        
      }
      
    },[currentEventKey])
  
    const isCurrentEventKey = currentEventKey === eventKey;

    
  
    return (
      <button
        type="button"
        className={isCurrentEventKey ? 'active_card inactive_card': 'inactive_card'}
        onClick={decoratedOnClick}
      >
        {children}
        <i 
         className={isCurrentEventKey ? 'icon-minus-accordion ': 'icon-plus-accordion'}
        ></i>
      </button>
    );
  }

  return (
    <div className="collapse-block prop_details">
    
             <div className="animated">
             
             </div>
             <div className="animated">
              <Accordion flush>
              { props.property_details_features_data.long_description &&
                <Card id={`accord_0`}>
                  <div className="accordion-header" >
                  <ContextAwareToggle eventKey="0" className="accordion-header">
                   Details & Key Features
                   
                  </ContextAwareToggle>
                  </div>
               
                  <Accordion.Collapse eventKey="0">
                    
                    <div className="accordion-card remove_mrgn accordion-card-first">
                      <p className="highlight-text">{HTMLReactParser(props.property_details_features_data.long_description.replace(/\*/g,'* '))}</p>
                     </div>
                      
                  </Accordion.Collapse>
                </Card>
                }


                {/* <Card id={`accord_1`}>
                <div className="accordion-header">
                  <ContextAwareToggle eventKey="1">
                    Development & Strategy
                  </ContextAwareToggle>
                  </div>
                  <Accordion.Collapse eventKey="1">
                    <div className="accordion-card accordion-card-second">
                    <h3>Our Objective</h3>
                    <p>Rightly called the city of future, Qetaifan Island is our signature luxury development in the Lusail city. The Qetaifan islands are located just off the eastern course of the Lusail city mainland. Our vision is to provide you with a sophisticated and comfortable lifestyle—a community offering a green facade and embraced by the calming waves of the sea.</p>
                    <h3>Our Plan</h3>
                    <p>The Qetaifan South islands have properties for sale in the villa zone and the lifestyle zone ( which comprises marina view & non – marina villa plots)</p>
                    <h3>Our Zones</h3>
                    <ul>
                      <li>The villa zone is an elite society of 263 serviced villas in private plots offering a fantastic living style for Ultra High Net Worth Individuals. Your next dream property in the Lusail city development project is closer than you think. The lifestyle zone is the most exquisite luxury destination providing you with high-end shopping outlets, sophisticated restaurants and cafes – amongst a selection of international amenities and picturesque walks. You can discover an excellent range of Lusail properties for sale in this zone.</li>
                      <li>Our vision is to provide you with a combination of a luxurious lifestyle, living by green areas while enjoying the breathtaking beaches. For this reason, the Lifestyle zone ( Marina) overlooks a wide promenade. We envision this incredible area as an upcoming leading lifestyle spot and a destination for exciting activities that engage you and your family.</li>
                      <li>At the Lifestyle non- marina zone, our commercial spaces, and offices are remarkable structures with world-class standards and facilities. The lavish beach club, officer club, harbour master, and charming waterfront townhouses offer you an ultimate standard of lifestyle right here in Qatar.</li>
                    </ul>
                    </div>
                  </Accordion.Collapse>
                </Card> */}

                
                { props.property_details_features_data.accommodation_summary.length > 0 &&
                  <Card id={`accord_2`}>
                    <div className="accordion-header">
                      <ContextAwareToggle eventKey="2">
                        Amenities
                      </ContextAwareToggle>
                    </div>
                    <Accordion.Collapse eventKey="2">
                      <div className="accordion-card accordion-card-third">
                        <ul>
                          {
                            props.property_details_features_data.accommodation_summary.map(amenitiesItem => {
                              return <>
                                <li>{amenitiesItem}</li>
                              </>
                            })
                          }
                        </ul>
                      </div>
                    </Accordion.Collapse>
                  </Card>
                }
                 </Accordion>
             </div>
        
    </div>
  )
}

export default CollapseBlock
import React from "react";
import "./StatusTag.scss";

const Statustag = (props) => { 
  
    return (
      props.tagStatus != "for sale" && props.tagStatus != "for rent" &&
      <span className={`status-tag ${props?.tagStatus === "sold" ? 'sold-tag' : 'let-tag'}`}>{props?.tagStatus}</span>
    )
}
export default Statustag;
import React,{useState} from "react"
import { Container,Row,Col } from "react-bootstrap"
import PropertyBenner from "./PropertyBanner/PropertyBenner";
import PropertyBenner2 from "./PropertyBanner/PropertyBenner_2";
import PropertyHeading from "./PropertyHeading/PropertyHeading";
import PropertyFacts from "./PropertyFacts/PropertyFacts";
import ProjectScore from "./ProjectScore/ProjectScore";
import CollapseBlock from "./CollapseBlock/CollapseBlock";
import PropertyCalculators from "./PropertyCalculators/PropertyCalculators";
import PropertyArea from "./PropertyArea/PropertyArea";
import MapComponent from "./Map/Map";
import VideoArea from "./VideoArea/VideoArea";
import SimilarProperty from "./SimilarProperty/SimilarProperty";
import MemberCard from "../MemberCard/MemberCard"
import { useStaticQuery, graphql } from "gatsby"
import wordsToNumbers from 'words-to-numbers';
import MemberImg from "../../images/person.jpg"
import "./PropertyDetail.scss"

import Modal from "react-bootstrap/Modal"
import BookViewingForm from "../../components/forms/book-a-viewing-form"
import { NegoDetails } from "../../queries/common_use_query"
import { useEffect } from "react";
import noImage from "../../images/no-image.jpg"
import { Helmet } from "react-helmet";
import HTMLParser from 'html-react-parser';
import { useLocation } from "@reach/router";

// styles

// markup
const PropertyDetail = ( props ) => {

  const [isOpen,setIsOpen] = useState(false);
  const [negoDet,setNegoDet] = useState('');

  //Prequalified link
  //console.log("property_details_data",props.property_details_data && props.property_details_data.property)

  var nego_details = props.property_details_data && props.property_details_data.property && props.property_details_data.property.negotiator_details;

  const {loading, error, data:negoData} = NegoDetails(nego_details?.owner ? nego_details.owner : '');
  var testimonial_count = props.testimonials ? props.testimonials.length : 0;
  var rating_count = 0;
  props.testimonials && props.testimonials.length > 0 && props.testimonials.map((item, ind)=>{
     rating_count += parseInt(wordsToNumbers(item.starRating));
 })
 var rating_avg = rating_count/testimonial_count;
 var avgRatingVal = parseFloat(rating_avg).toFixed(1)

  useEffect(()=>{
    negoData && setNegoDet(negoData);
  },[negoData])

  // // Property details right side block
  const data = useStaticQuery(graphql`
    query {
      glstrapi {
        globalModule {
          PropDetails_Right_Side_Block {
                id
                Title
                Choose_Office {
                  Name
                  Email
                  URL
                  id
                  Phone
                  Direct_Phone
                  Office_Phone
                  Whatsapp_No
                  Image {
                    url
                    alternativeText
                  }
                }
                Content
                CTA_2_URL
                CTA_2_Label
                CTA_1_URL
                CTA_1_Label
          }
            
        }
      }
    }

  `)

  //console.log("right_side_block", data);
  var right_side_block = data && data.glstrapi.globalModule && data.glstrapi.globalModule.PropDetails_Right_Side_Block;
  // Property details right side block


  // Prequalified link
  let prequalifyUrl = '/pre-qualified/secondary-market';
  
  //console.log("props.property_details_data.property.status", props.property_details_data.property.status)
  if(right_side_block){
    if(props.property_details_data.property.department === "residential" && (props.property_details_data.property.status === "for rent" || props.property_details_data.property.status === "to rent"))
    {
      prequalifyUrl = "/pre-qualified/rental-listing";
      
    } else if(props.property_details_data.property.department === "residential" && props.property_details_data.property.status === "for sale" && (props.property_details_data.property.sublisting_type === "Secondary Market" || props.property_details_data.property.sublisting_type === "Resale" || props.property_details_data.property.sublisting_type === "Direct Sale"))
    {
      prequalifyUrl = prequalifyUrl;
    } else if(props.property_details_data.property.department === "residential" && props.property_details_data.property.status === "for sale" && props.property_details_data.property.sublisting_type === "Primary Market") {
      
      prequalifyUrl = "/pre-qualified/primary-market";
    } else if(props.property_details_data.property.department === "residential" && props.property_details_data.property.status === "for sale" && props.property_details_data.property.sublisting_type === "Lease to Own") {
      
      prequalifyUrl = "/pre-qualified/lease-to-own";
    }  else if(props.property_details_data.property.department === "residential" && props.property_details_data.property.status === "for sale" && props.property_details_data.property.sublisting_type === "Land/Plot") {
      
      prequalifyUrl = "/pre-qualified/land-plot-lead";
    } else if((props.property_details_data.property.department === "residential" || props.property_details_data.property.department === "commercial") && props.property_details_data.property.sublisting_type === "Off Plan") {

      prequalifyUrl = "/pre-qualified/offplan-projects";
    } else if(props.property_details_data.property.department === "commercial" && props.property_details_data.property.status === "for sale" && (props.property_details_data.property.sublisting_type === "Secondary Market" || props.property_details_data.property.sublisting_type === "Resale" || props.property_details_data.property.sublisting_type === "Direct Sale")) {

      prequalifyUrl = "/pre-qualified/commercial-sale";
    } else if(props.property_details_data.property.department === "commercial" && (props.property_details_data.property.status === "for rent" || props.property_details_data.property.status === "to rent") && (props.property_details_data.property.sublisting_type === "Secondary Market" || props.property_details_data.property.sublisting_type === "Resale" || props.property_details_data.property.sublisting_type === "Direct Sale")) {

      prequalifyUrl = "/pre-qualified/commercial-lease";
    }
    
  }

  const location = useLocation();
  const thePath  = location.pathname
  var pStatus  = props?.property_details_data?.property.status
  var availability = ""
  if( pStatus === 'sold'){
    availability = "SoldOut"
  }else if(pStatus === 'rented'){
    availability = "OutofStock "
  }else if(pStatus === 'for sale' || pStatus === 'for rent'){
    availability = "InStock"
  }

  //console.log ("xxxxxxxxxxxx", props.property_details_data.property)

  return (
    <React.Fragment>

      <Helmet>
          <script type="application/ld+json">{`{
          "@context" : "http://schema.org",
          "@type" : "Product",
          "name" : "${props.property_details_data.property.title} in ${props.property_details_data.property.display_address}",
          "image" : "${props.property_details_data.property.images[10]?.url ? props.property_details_data.property.images[10]?.url : props.property_details_data.property.images[1]?.url }",
          "description" : "Find ${props.property_details_data.property.title} with The Pearl Gates at QR ${props.property_details_data.property.price}. Contact The Pearl Gates, for more information about the Properties.",
          "brand" : {
              "@type" : "Organization",
              "name" : "The Pearl Gates",
              "logo" : "https://ggfx-pearlgates2.s3.eu-west-2.amazonaws.com/i.prod/pb_logo_2_043eb3ad46.png"
          },
          "aggregateRating": {
                  "@type": "AggregateRating",
                  "ratingValue": "${avgRatingVal}",
                  "reviewCount": "${testimonial_count}"
              },
          "offers": {
                  "@type": "Offer",
                  "url": "${thePath}",
                  "priceCurrency": "QAR",
                  "price": "${props.property_details_data.property.price}",
                  "availability": "https://schema.org/${availability}"
                  }
                  }
          }`}</script>
      </Helmet>

      { 
        props.property_details_data && props.property_details_data.property.images && props.property_details_data.property.images.length > 0 ? (
          props.property_details_data.property.virtual_tour && props.property_details_data.property.virtual_tour.length > 0 ? 
          <PropertyBenner2 property_details_banner_data={props.property_details_data.property}/>
          :
          <PropertyBenner property_details_banner_data={props.property_details_data.property}/>
        ) : (
          <div className="no_banner">
            <img src={noImage} alt="" />
          </div>
        )        
      }
      <div className="property-wrapper">
        <Container>
          <Row>
            <Col xl={8}>
              <a href={'javascript:;'} onClick={(e) => window.history.back(-1)}  className="back-link">
                <i className="right-arrow"></i>Back to Property Listing
              </a>
              { props.property_details_data &&
                <PropertyHeading property_details_heading_data={props.property_details_data.property} h1_title={props.h1_title} />
              }
            </Col>
            </Row>
        </Container>
      </div>
      <div className="block-wrapper">
        <Container>
          <Row>
            <Col xl={8}>
              { props.property_details_data &&
                <PropertyFacts property_details_facts_data={props.property_details_data.property}/>
              }

              { props.property_details_data && (props.property_details_data.property.long_description || props.property_details_data.property.accommodation_summary.length > 0) && 
                <CollapseBlock property_details_features_data={props.property_details_data.property}/>
              }
              {
                props.property_details_data && props.property_details_data.property.search_type === "sales" && <PropertyCalculators calc_price={props.property_details_data.property.price}/>
              }
              
              { props.property_details_data && props.property_details_data.property && props.property_details_data.property.floorplan && props.property_details_data.property.floorplan.length > 0 && 
                <PropertyArea property_details_floorplan_data={props.property_details_data.property}/>
              }
            </Col>
            <Col xl={4}>
              <div className="member-card-block property_details">
                {
                  right_side_block && 
                  <MemberCard
                    title={props.property_details_data.property.status === "rented" || props.property_details_data.property.status === "sold" ? "Are you interested in similar property?" : right_side_block.Title}
                    content={right_side_block.Content}
                    btnText={props.property_details_data.property.status === "rented" || props.property_details_data.property.status === "sold" ? "" : "Book a Viewing"}
                    btnTextSecondary={`${right_side_block.CTA_2_Label}`}
                    btnLink={"book_a_viewing"}
                    btnLinkSecondary={`${prequalifyUrl}?pid=${props.property_details_data.property.propertyID}`}
                    Office={negoDet && negoDet.peoples.length > 0 ? negoDet.peoples[0] : right_side_block.Choose_Office}    
                    openModalForm={() => setIsOpen(true)}
                    WhatsappText={props.property_details_data.property.title+', '+props.property_details_data.property.display_address}     
                    location={props.location}
                  />
                }
                
              </div>
            </Col>
          </Row>
        </Container>
      </div> 
      { props.property_details_data &&
        <MapComponent property_details_map_data={props.property_details_data.property}/>
      }
      {
        props.property_details_data &&
        <VideoArea property_details_area_guide={props.property_details_data.property.display_address}/>
      }
      {
        props.property_details_data && 
        <SimilarProperty property_details_similar_property_id={props.property_details_data.property.id}/>
      }


      <Modal
            id="valuation_modal"
            show={isOpen}
            onHide={() => setIsOpen(false)}
            backdrop="static"
            keyboard={false}
            className="member-contact-modal"
          >
            {/* <Button variant="" onClick={this.closeModal} className="p-0 closepopup"><i className="icon icon-close-popup"></i></Button> */}
            <Modal.Header closeButton className="contact-close-btn memberPopup">
              <Modal.Title className="w-100"><h3 className="text-center content-primary-color mb-0">Book a Viewing</h3></Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <BookViewingForm to_email_id={""} formName={`Book a Viewing`} location={props.location} formLabel={props.property_details_data.property} formType={'book_a_viewing'}  />
            </Modal.Body>
      </Modal>
    </React.Fragment>
  )
}

export default PropertyDetail
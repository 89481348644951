import React,{useState} from "react"
import { Link } from "@StarberryUtils";
import PropertyImg from '../../../images/map-area.jpg'
import Slider from "react-slick"

import FsLightbox from 'fslightbox-react';
import "./PropertyArea.scss"

import Lightbox from 'react-image-lightbox';
import 'react-image-lightbox/style.css';
// styles

// markup
const PropertyArea = ( props ) => {

  const [toggler, setToggler] = useState(false);

  const [photoIndex, setPhotoIndex] = useState(0);
  const [isOpen, setIsOpen] = useState(false);

  const openLighboxMobile = (e, ind) => {
    e.preventDefault();
    setPhotoIndex(ind);
    setIsOpen(true);
  } 

  const PropertyAreaMap = [
  {
    ProperyAreaImg:PropertyImg,
    ProperyAreaName:'2 Bedroom Apartment',
    ProperyAreaUnit:'From 4,886,229 QR'
  },
  {
    ProperyAreaImg:PropertyImg,
    ProperyAreaName:'2 Bedroom Apartment',
    ProperyAreaUnit:'From 4,886,229 QR'
  },
  {
    ProperyAreaImg:PropertyImg,
    ProperyAreaName:'2 Bedroom Apartment',
    ProperyAreaUnit:'From 4,886,229 QR'
  },
  {
    ProperyAreaImg:PropertyImg,
    ProperyAreaName:'2 Bedroom Apartment',
    ProperyAreaUnit:'From 4,886,229 QR'
  }

]
const settings = {
  dots: true,
  speed: 800,
  slidesToShow: 4,
  slidesToScroll: 1,
  infinite: false,
  arrows: false,
  mobileFirst: true,
  responsive: [
    {
      breakpoint: 1199,
      settings: {
        slidesToShow: 4,
        slidesToScroll: 3,
        infinite: false,
        dots: false,
      },
    },
    {
      breakpoint: 991,
      settings: {
        slidesToShow: 3,
        slidesToScroll: 3,
        infinite: false,
        dots: false,
      },
    },
    {
      breakpoint: 767,
      settings: {
        slidesToShow: 2,
        slidesToScroll: 2,
        infinite: false,
        dots: true,
      },
    },
    {
      breakpoint: 99999,
      //settings: "unslick",
    },
  ],
}

var lightImages2 = PropertyAreaMap && PropertyAreaMap.map(img => img.ProperyAreaImg);
  return (
    props.property_details_floorplan_data.floorplan &&
    <>
      <div className="property-area-block" id="floorplans">
         <div className="animated"> 
          <div className="property-area-title">
            <h2>Floorplans</h2>
          </div>
          <div className="property-area-map">
            <Slider {...settings}>
              {PropertyAreaMap.map((item, index)=> <div key={index}>
                <div className="property-area-img" onClick={(e) => openLighboxMobile(e, index)}>
                  <img src={item.ProperyAreaImg} alt=""/>
                </div>
                <div className="property-name">
                  <h3><Link href="#" className="">{item.ProperyAreaName}</Link></h3>
                  <span>{item.ProperyAreaUnit}</span>
                </div>
              </div>
              )}
            </Slider>
          </div>
         </div>
      </div>

      {/* <FsLightbox
        toggler={ toggler }
        sources= {
          PropertyAreaMap.map(lightboximg => {
            const lightbox_image_url = lightboximg
            // console.log("$$$$", lightbox_image_url.ProperyAreaImg)
            return <>
              <img src={lightbox_image_url.ProperyAreaImg} alt="" />
            </>
          })
        }
      /> */}

        {isOpen && (
          <Lightbox
            mainSrc={lightImages2[photoIndex]}
            nextSrc={lightImages2[(photoIndex + 1) % lightImages2.length]}
            prevSrc={lightImages2[(photoIndex + lightImages2.length - 1) % lightImages2.length]}
            onCloseRequest={() => setIsOpen(false)}
            onMovePrevRequest={() =>
              setPhotoIndex((photoIndex + lightImages2.length - 1) % lightImages2.length)
            }
            onMoveNextRequest={() =>
              setPhotoIndex((photoIndex + 1) % lightImages2.length)
            }
          />
        )}
    </>
  )
}

export default PropertyArea